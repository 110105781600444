const country = [
  {
    name: "Algérie",
    code: "DZ",
    dial_code: "+213",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/DZ.svg",
  },
  {
    name: "Angola",
    code: "AO",
    dial_code: "+244",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/AO.svg",
  },
  {
    name: "Bénin",
    code: "BJ",
    dial_code: "+229",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/BJ.svg",
  },
  {
    name: "Botswana",
    code: "BW",
    dial_code: "+267",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/BW.svg",
  },
  {
    name: "Burkina Faso",
    code: "BF",
    dial_code: "+226",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/BF.svg",
  },
  {
    name: "Burundi",
    code: "BI",
    dial_code: "+257",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/BI.svg",
  },
  {
    name: "Cameroon",
    code: "CM",
    dial_code: "+237",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/CM.svg",
  },
  {
    name: "Cap-Vert",
    code: "CV",
    dial_code: "+238",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/CV.svg",
  },
  {
    name: "République centrafricaine",
    code: "CF",
    dial_code: "+236",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/CF.svg",
  },
  {
    name: "Tchad",
    code: "TD",
    dial_code: "+235",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/TD.svg",
  },
  {
    name: "Comores",
    code: "KM",
    dial_code: "+269",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/KM.svg",
  },
  {
    name: "République du Congo",
    code: "CG",
    dial_code: "+242",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/CG.svg",
  },
  {
    name: "République démocratique du Congo",
    code: "CD",
    dial_code: "+243",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/CD.svg",
  },
  {
    name: "Côte d'Ivoire",
    code: "CI",
    dial_code: "+225",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/CI.svg",
  },
  {
    name: "Djibouti",
    code: "DJ",
    dial_code: "+253",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/DJ.svg",
  },
  {
    name: "Égypte",
    code: "EG",
    dial_code: "+20",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/EG.svg",
  },
  {
    name: "Sahara occidental",
    code: "EH",
    dial_code: "+212",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/EH.svg",
  },
  {
    name: "Guinée équatoriale",
    code: "GQ",
    dial_code: "+240",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/GQ.svg",
  },
  {
    name: "Érythrée",
    code: "ER",
    dial_code: "+291",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/ER.svg",
  },
  {
    name: "Éthiopie",
    code: "ET",
    dial_code: "+251",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/ET.svg",
  },
  {
    name: "Gabon",
    code: "GA",
    dial_code: "+241",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/GA.svg",
  },
  {
    name: "Gambie",
    code: "GM",
    dial_code: "+220",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/GM.svg",
  },
  {
    name: "Ghana",
    code: "GH",
    dial_code: "+233",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/GH.svg",
  },
  {
    name: "Guinée",
    code: "GN",
    dial_code: "+224",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/GN.svg",
  },
  {
    name: "Guinée-Bissau",
    code: "GW",
    dial_code: "+245",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/GW.svg",
  },
  {
    name: "Kenya",
    code: "KE",
    dial_code: "+254",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/KE.svg",
  },
  {
    name: "Lesotho",
    code: "LS",
    dial_code: "+266",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/LS.svg",
  },
  {
    name: "Libéria",
    code: "LR",
    dial_code: "+231",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/LR.svg",
  },
  {
    name: "Libye",
    code: "LY",
    dial_code: "+218",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/LY.svg",
  },
  {
    name: "Madagascar",
    code: "MG",
    dial_code: "+261",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/MG.svg",
  },
  {
    name: "Malawi",
    code: "MW",
    dial_code: "+265",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/MW.svg",
  },
  {
    name: "Mali",
    code: "ML",
    dial_code: "+223",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/ML.svg",
  },
  {
    name: "Mauritanie",
    code: "MR",
    dial_code: "+222",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/MR.svg",
  },
  {
    name: "Maurice",
    code: "MU",
    dial_code: "+230",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/MU.svg",
  },
  {
    name: "Maroc",
    code: "MA",
    dial_code: "+212",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/MA.svg",
  },
  {
    name: "Mozambique",
    code: "MZ",
    dial_code: "+258",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/MZ.svg",
  },
  {
    name: "Namibie",
    code: "NA",
    dial_code: "+264",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/NA.svg",
  },
  {
    name: "Niger",
    code: "NE",
    dial_code: "+227",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/NE.svg",
  },
  {
    name: "Nigéria",
    code: "NG",
    dial_code: "+234",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/NG.svg",
  },
  {
    name: "Rwanda",
    code: "RW",
    dial_code: "+250",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/RW.svg",
  },
  {
    name: "São Tomé et Principe",
    code: "ST",
    dial_code: "+239",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/ST.svg",
  },
  {
    name: "Sénégal",
    code: "SN",
    dial_code: "+221",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/SN.svg",
  },
  {
    name: "Seychelles",
    code: "SC",
    dial_code: "+248",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/SC.svg",
  },
  {
    name: "Sierra Leone",
    code: "SL",
    dial_code: "+232",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/SL.svg",
  },
  {
    name: "Somalie",
    code: "SO",
    dial_code: "+252",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/SO.svg",
  },
  {
    name: "Afrique du Sud",
    code: "ZA",
    dial_code: "+27",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/ZA.svg",
  },
  {
    name: "Sud-Soudan",
    code: "SS",
    dial_code: "+211",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/SS.svg",
  },
  {
    name: "Soudan",
    code: "SD",
    dial_code: "+249",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/SD.svg",
  },
  {
    name: "Eswatini",
    code: "SZ",
    dial_code: "+268",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/SZ.svg",
  },
  {
    name: "Tanzanie",
    code: "TZ",
    dial_code: "+255",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/TZ.svg",
  },
  {
    name: "Togo",
    code: "TG",
    dial_code: "+228",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/TG.svg",
  },
  {
    name: "Tunisie",
    code: "TN",
    dial_code: "+216",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/TN.svg",
  },
  {
    name: "Ouganda",
    code: "UG",
    dial_code: "+256",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/UG.svg",
  },
  {
    name: "Zambie",
    code: "ZM",
    dial_code: "+260",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/ZM.svg",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    dial_code: "+263",
    flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/ZW.svg",
  },
];
export default country;
