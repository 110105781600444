/**
 *PROJECT Travigo
 * Created by WebStorm.
 * Author Khaliq ALI<hello@kgeek.me>)
 * Date 30/12/2021
 * TIME 18:03
 **/
export default {
  baseURL: "https://api.kgeek.me/api/v1",
};
