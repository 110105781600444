<template>
  <div class="select-base">
    <div class="begin">
      <div class="content">
        <div class="select flex items-center cursor-pointer" @click="showList">
          <div class="select-text flex items-center">
            <!--<div
              class="picture ml-4 mr-4 bg-no-repeat bg-cover w-10 h-7 rounded"
              v-bind:style="{
                backgroundImage: 'url(' + isSelected?.flag + ')',
              }"
            ></div>-->
            <div class="code px-2 text-lg text-gray-900">
              {{ isSelected?.dial_code }}
            </div>
          </div>
          <div class="icons flex ml-0.5">
            <icon
              v-if="!isList"
              :data="icons.down"
              original
              color="#999"
              width="20"
              height="20"
            />
            <icon
              v-if="isList"
              :data="icons.up"
              original
              color="#999"
              width="10"
              height="10"
            />
          </div>
        </div>
        <div
          v-if="isList"
          class="select-list absolute bg-white z-10 cursor-pointer max-h-64 overflow-y-scroll border w-80"
        >
          <div
            v-for="(result, i) in allCountry"
            :key="i"
            class="select-agent px-4 py-2 flex items-center"
            @click="setResult(i)"
          >
            <div
              class="picture mr-2 bg-no-repeat bg-cover w-5 h-3.5"
              v-bind:style="{ backgroundImage: 'url(' + result.flag + ')' }"
            ></div>
            <div class="code mr-2 font-bold text-sm">
              {{ result.dial_code }}
            </div>
            <div class="name font-bold text-sm">{{ result.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import country from "../../components/countrie/allCountries";
import down from "../../assets/icons/ic_down.svg";
import http from "../../plugins/https";

export default {
  name: "select-base",
  data() {
    return {
      icons: { down },
      isList: false,
      allCountry: [],
      isSelected: {
        name: "Bénin",
        code: "BJ",
        dial_code: "+229",
        flag: "https://purecatamphetamine.github.io/country-flag-icons/3x2/BJ.svg",
      },
    };
  },
  methods: {
    /* GET COUNTRY */
    async getcountry() {
      // console.log('seeeee', country)
      country.forEach((item) => {
        this.allCountry.push(item);
      });
      let result = null;
      await http
        .get("https://restcountries.com/v3.1/all", {})
        .then((response) => {
          console.log(response);

          result = response;
        })
        .catch((error) => {
          console.log(error);
        });
      result.forEach((element) => {
        console.log(element);
        if (element.region !== "Africa") {
          this.allCountry.push({
            name: element.translations.fra.common,
            code: element.cca3,
            dial_code: element.idd.root + element.idd.suffixes[0],
            flag: element.flags.svg,
          });
        }
        this.allCountry.sort((x, y) => {
          let a = x.name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
            b = y.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          return a == b ? 0 : a > b ? 1 : -1;
        });
      });
      return result;
    },
    showList() {
      // OPEN AND CLOSE LIST
      this.isList = !this.isList;
    },
    setResult(index) {
      // SELECT COUNTRY
      this.isList = false;
      this.isSelected = this.allCountry[index];
      this.$emit("updateCountryCode", this.allCountry[index]);
    },
  },

  beforeMount() {
    this.getcountry();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/sass/variables";
.select {
  /* border-bottom: 1px solid $rema_border;*/
  padding: 0.8rem 0;
}
.select-list {
  width: 22.75rem;
}
.select-agent:hover {
  background: rgb(243 244 246);
}
.svg {
  fill: transparent;
}
</style>
