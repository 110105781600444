<template>
  <div class="w-full bg-greenNew1">
    <div class="lg:flex justify-between space-y-14 py-5 lg:mx-28 mx-5">
      <div>
        <div class="font-semibold text-lg text-gray-100 mb-4 lg:mt-14">
          COMPAGNIE
        </div>
        <div class="font-medium text-base text-white mb-3">
          <a href="https://pharma.remaapp.com/about">A propos</a>
        </div>
        <div class="font-medium text-base text-white mb-3">
          <a
            href="https://www.linkedin.com/company/rema-r%C3%A9seau-d-echange-entre-m%C3%A9decins-d-afrique/"
            >LinkedIn</a
          >
        </div>
        <div class="font-medium text-base text-white mb-3">
          <a href="https://www.facebook.com/remaapp/?ref=br_rs&_rdc=1&_rdr"
            >Facebook</a
          >
        </div>
      </div>
      <div>
        <div class="font-semibold text-lg text-gray-100 mb-4">SOLUTIONS</div>
        <div class="font-medium text-base text-white mb-3">
          <a href="https://remaapp.com/">Médecins</a>
        </div>
        <div class="font-medium text-base text-white mb-3">
          <a href="https://remaapp.com/remaBusiness/">Entreprises</a>
        </div>
        <div class="font-medium text-base text-white mb-3">
          <a href="https://remaapp.com/faq">FAQ</a>
        </div>
      </div>
      <div>
        <div class="font-semibold text-lg text-gray-100 mb-4">CONTACT</div>
        <div class="font-medium text-base text-white mb-3">
          C/982 Hinde sud Cotonou Bénin
        </div>
        <div class="font-medium text-base text-white mb-3">
          contact@remaapp.com
        </div>
        <div class="font-medium text-base text-white mb-3">+229 96508130</div>
      </div>
      <div>
        <div class="font-semibold text-lg text-gray-100 mb-4">
          <a href="">REMA Medical Technologies</a>
        </div>
        <div class="font-medium text-base text-white mb-3">
          <a href="https://remaapp.com/TermsOfService"
            >Conditions générales d’utilisation</a
          >
        </div>
        <div class="font-medium text-base text-white">
          <a href="https://remaapp.com/privacyPolice"
            >Politique de confidentialité</a
          >
        </div>
      </div>
    </div>
    <div
      class="lg:flex justify-between items-center lg:mx-28 mx-5 border-t border-gray-200 py-8"
    >
      <div>
        <div class="w-52 h-12">
          <img :src="images.logoFooter" alt="logo" original />
        </div>
      </div>
      <div class="font-medium text-base leading-6 text-white">
        <div>{{ $t("right") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
/*Import Images*/
import logoFooter from "../../assets/images/img_logoFooter.webp";

export default {
  name: "index",
  data() {
    return {
      icons: {},
      images: {
        logoFooter,
      },
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/sass/variables.scss";
a {
  color: unset;
}
</style>
