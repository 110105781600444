<template>
  <!-- Header For Large Screen-->
  <div class="header fixed z-50 w-full">
    <div
      class="flex justify-between w-full border border-gray-100 bg-carouselColor items-center py-4"
    >
      <div class="w-52 lg:mx-28 mx-7 cursor-pointer">
        <a href="/">
          <img :src="images.logo" alt="logo" original />
        </a>
      </div>
      <div
        class="lg:flex hidden md:hidden space-x-7 font-medium text-base leading-7 text-aColor lg:-ml-96"
      >
        <div><a href="#objectifs">Objectifs</a></div>
        <div><a href="#programme">Programme</a></div>
        <div>
          <a href="#renseignements">Renseignements</a>
        </div>
      </div>
      <div class="lg:mr-28 mr-7">
        <a href="https://learning.remaapp.com/" target="_blank">
          <button
            class="font-semibold text-base leading-5 text-white bg-greenNew1 rounded-lg px-6 py-3.5 cursor-pointer"
          >
            {{ $t("button") }}
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
/*Import Images*/
import logo from "../../assets/images/img_logo.webp";

export default {
  name: "index",
  data() {
    return {
      icons: {},
      images: {
        logo,
      },
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "src/assets/styles/sass/variables.scss";
a {
  color: unset;
}

.header {
  background-color: white;
}
</style>
